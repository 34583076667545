import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
      ASDF: westack {
          tool(id: "asdf"){
              ...ToolInfo
          }
      }
      Solid: westack {
        tool(id: "solidui-solid"){
          ...ToolInfo
        }
      }
      OneDev: westack {
        tool(id: "onedev"){
          ...ToolInfo
        }
      }
      TABIX: westack {
        tool(id: "tabix"){
          ...ToolInfo
        }
      }
      Airy: westack {
        tool(id: "airy"){
          ...ToolInfo
        }
      }
      CODING: westack {
        tool(id: "coding"){
          ...ToolInfo
        }
      }
      Utools: westack {
        tool(id: "utools"){
          ...ToolInfo
        }
      }
      Cantonese: westack {
        tool(id: "cantonese"){
          ...ToolInfo
        }
      }
      The_OldOS: westack {
        tool(id: "the-oldos-project"){
          ...ToolInfo
        }
      }
      NymphCast: westack {
        tool(id: "nymphcast"){
          ...ToolInfo
        }
      }
  }`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.ASDF.tool} mdxType="Tool" />
    <Tool tool={props.data.Solid.tool} mdxType="Tool" />
    <Tool tool={props.data.OneDev.tool} mdxType="Tool" />
    <Tool tool={props.data.TABIX.tool} mdxType="Tool" />
    <Tool tool={props.data.Airy.tool} mdxType="Tool" />
    <Tool tool={props.data.CODING.tool} mdxType="Tool" />
    <Tool tool={props.data.Utools.tool} mdxType="Tool" />
    <Tool tool={props.data.Cantonese.tool} mdxType="Tool" />
    <Tool tool={props.data.The_OldOS.tool} mdxType="Tool" />
    <Tool tool={props.data.NymphCast.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      